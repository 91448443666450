import React from 'react'
import Deco from '../../../Assets/Deco.svg'
import DecoSub1 from '../../../Assets/DecoSub1.svg'
import DecoSub2 from '../../../Assets/DecoSub2.svg'
import DecoSub3 from '../../../Assets/DecoSub3.svg'
import DecoSub4 from '../../../Assets/DecoSub4.svg'

function HeroMovistar() {
    return (
        <div class="bg-[#F99C00] w-full h-min 2xl:h-screen">
            <div
                class="container w-full mx-auto flex md:flex-row flex-col-reverse px-5 md:px-0 justify-center 2xl:grid grid-cols-2 gap-10 2xl:mr-0 pb-10"
            >
                <div class="flex flex-col items-center gap-5">
                    <img
                        src={Deco}
                        alt="Deco"
                        class="2xl:w-full 2xl:h-full w-[490px]"
                    />
                    <div class="grid grid-cols-1 md:grid-cols-4 gap-5 w-full">
                        <div
                            class="bg-white rounded-[40px] flex items-center flex-col justify-center px-2 py-7"
                        >
                            <img
                                src={DecoSub1}
                                class="h-[80px] w-[100px]"
                                alt="Deco"
                            />
                            <h1 class="text-base font-bold text-center mt-2">
                                Deco L14<br />
                                <span class="font-normal">incluido</span>
                            </h1>
                        </div>
                        <div
                            class="bg-white rounded-[40px] flex items-center flex-col justify-center px-2 py-7"
                        >
                            <img
                                src={DecoSub2}
                                class="h-[80px] w-[100px]"
                                alt="Deco"
                            />
                            <h1 class="text-base font-bold text-center mt-2">
                                Kit completo de<br />
                                <span class="font-normal">antena y accesorios</span>
                            </h1>
                        </div>
                        <div
                            class="bg-white rounded-[40px] flex items-center flex-col justify-center px-2 py-7"
                        >
                            <img
                                src={DecoSub3}
                                class="h-[80px] w-[100px]"
                                alt="Deco"
                            />
                            <h1 class="text-base font-bold text-center mt-2">
                                Activación del<br />
                                <span class="font-normal">Plan Mega</span>
                            </h1>
                        </div>
                        <div
                            class="bg-white rounded-[40px] flex items-center flex-col justify-center px-2 py-7"
                        >
                            <img
                                src={DecoSub4}
                                class="h-[80px] w-[100px]"
                                alt="Deco"
                            />
                            <h1 class="text-base font-bold text-center mt-2">
                                Instalación y soporte<br />
                                <span class="font-normal">técnico garantizado</span>
                            </h1>
                        </div>
                    </div>
                </div>
                <div class="flex flex-col gap-5 justify-between mt-4">
                    <div
                        class="bg-white md:w-[360px] rounded-t-[40px] rounded-br-[40px]"
                    >
                        <p class="text-5xl md:text-4xl 2xl:text-6xl p-10">
                            ¿Eras cliente MovistarTV
                            <span class="font-bold"> y perdiste tu servicio? </span>
                        </p>
                    </div>
                    <p class="text-2xl text-center md:text-start">
                        Pásate a
                        <span class="font-bold"> SimpleTV </span>
                        por solo / <span class="text-5xl font-bold"> $59 </span>
                    </p>
                    <div
                        class="border border-white rounded-b-[40px] rounded-tl-[40px] md:w-[360px] 2xl:h-[282px] h-[200px] p-5 relative flex items-center"
                    >
                        <p class="text-xl">
                            Descubre la alternativa perfecta con SimpleTV <span
                                class="font-bold"
                            >
                                Instalación rápida, kit completo, y acceso a Simpleplus.
                            </span>
                        </p>
                        <button
                            onClick={() => {
                                document.getElementById('afiliados').scrollIntoView({ behavior: 'smooth', block: 'center' })
                            }}
                            class="bg-white text-black h-[50px] rounded-[20px] absolute -bottom-6 left-20 p-5 text-center flex justify-center items-center
                    hover:bg-[#F99C00] hover:text-white transition-all border border-white"
                        >
                            <p class="text-base font-bold">Quiero más información</p>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default HeroMovistar