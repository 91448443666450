import { BrowserRouter, Route, Routes } from "react-router-dom";
import { lazy, Suspense } from 'react';
import axios from "axios";
import { useEffect, useState } from "react";
import AfiliadosScreen from "./Components/Screen/AfiliadosScreen/index.js";
import HomeScreen from "./Components/Screen/HomeScreen/index.js";
import FormularioScreen from "./Components/Screen/FormularioScreen/index.js";
import PoliticasPrivacidadScreen from "./Components/Screen/PoliticasScreen/index.js";
import DecoScreen from "./Components/Screen/DecoScreen/index.js";
import ReactDOM from 'react-dom';
import MovistarScreen from "./Components/Screen/MovistarScreen/index.jsx";

function App() {

  const [region, setRegions] = useState(null);
  const [url, setUrl] = useState(null);

  const getRegions = async () => {
    try {
      const response = await axios('https://vb10e3v01k.execute-api.sa-east-1.amazonaws.com/production/afiliados/regions');
      setRegions(response.data.responseAfiliados);
    } catch (error) {
      console.error('Error fetching regions:', error);
    }
  };

  const createArrayWithUrl = () => {
    if (!region) return;

    const array = [];

    region.forEach(ciudad => {
      ciudad.afiliados.forEach(afiliado => {
        let url = `/${cleanString(ciudad.name)}`;

        if (afiliado.ciudad) {
          url += `/${cleanString(afiliado.ciudad)}`;
        }

        url += `/${cleanString(afiliado.url)}`;

        if (afiliado.diff) {
          url += `/${cleanString(afiliado.diff)}`;
        }
        array.push({
          url,
          id: afiliado.id,
        });
      });
    });

    setUrl(array);
  };

  const cleanString = (string) => {
    return string.toLowerCase().replace(/\s+/g, '').normalize('NFD')
      .replace(/([aeio])\u0301|(u)[\u0301\u0308]/gi, "$1$2")
      .normalize();
  }


  useEffect(() => {
    getRegions();
  }, []);

  useEffect(() => {
    if (region !== null) {
      createArrayWithUrl();
    }
  }, [region]);


  const LazyHome = lazy(() => import('./Components/Screen/HomeScreen/index.js'));
  return (
    <BrowserRouter>


      <Routes>
        <Route path='/' element={
          <Suspense fallback={< div className="w-full h-screen" > <div className="p-52"><p className="text-[#0072CE] text-2xl">Cargando...</p></div></div >}>
            <LazyHome />
          </Suspense>
        } />
        <Route path='/decodificador-LH01' element={<DecoScreen />} />
        <Route path='/atraccion-cliente' element={<MovistarScreen />} />
        <Route path='/formulario' element={<FormularioScreen />} />
        <Route path="/politicas-de-privacidad" element={<PoliticasPrivacidadScreen />} />
        {
          url && url.map((path, index) => {
            return <Route path={path.url} element={<AfiliadosScreen id={path.id} />} />
          })
        }

      </Routes>

    </BrowserRouter>
  );
}

export default App;
