import { Input, Select } from 'antd'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'


function Afiliados({ }) {

    const refInputSearch = React.useRef(null)
    const [search, setSearch] = useState({ atr: '', result: [] })
    const [sortType, setSortType] = useState('asc')
    const [filter, setFilter] = useState('all')
    const [afiliados, setAfiliados] = useState(null);
    const [isLoading, setIsLoading] = useState(false);


    // const statesSet = new Set(data.map(afiliado => afiliado.data.state))


    const getAfiliados = async () => {
        setIsLoading(true);
        try {
            const data = await axios('https://vb10e3v01k.execute-api.sa-east-1.amazonaws.com/production/afiliados');  // Desestructurar la respuesta
            const filterMovistar = data.data.responseAfiliados.filter(afiliado => afiliado.content).filter(afiliado => afiliado.content.movistar)

            setAfiliados(filterMovistar);

        } catch (error) {
            console.error('Error fetching afiliados:', error);
        } finally {
            setIsLoading(false);
        }
    };

    const cleanString = (string) => {
        return string.toLowerCase().replace(/\s+/g, '').normalize('NFD')
            .replace(/([aeio])\u0301|(u)[\u0301\u0308]/gi, "$1$2")
            .normalize();
    }


    // const handleSearch = (e) => {
    //     setFilter('all')
    //     const value = e.target.value
    //     setSearch({ ...search, atr: value })
    //     const result = afiliados.filter(afiliado => afiliado?.nombre_comercial.toLowerCase().includes(value.toLowerCase()))
    //     setSearch({ ...search, result: result })

    // }

    // const handleFilter = () => {
    //     if (filter === 'all') {
    //         return setSearch({ ...search, result: data })
    //     }
    //     const result = data.filter(afiliado => afiliado.data.state === filter)
    //     setSearch({ ...search, result: result })
    // }


    // useEffect(() => {
    //     handleFilter()
    // }, [filter])

    useEffect(() => {
        getAfiliados()
    }, [])





    return (
        <div class="w-full max-w-[800px] flex flex-col gap-2 h-screen mx-auto py-10 px-5 md:px-0">
            <div
                class="border border-[#F99C00] w-full h-full flex flex-col items-center justify-center rounded-[35px]"
            >
                <h3 class="font-bold text-4xl py-5 text-center md:text-start">Listado de puntos de ventas</h3>

                <div className='grid md:grid-cols-4 w-full gap-5 p-5'>

                    {/* <Input placeholder="Buscar por nombre"
                        ref={refInputSearch}
                        onChange={handleSearch}
                        className='p-2'
                    /> */}

                    <Select defaultValue="asc" className='h-full w-full' onChange={(value) => setSortType(value)}>
                        <Select.Option value="asc">Ascendente</Select.Option>
                        <Select.Option value="desc">Descendente</Select.Option>
                    </Select>

                    {/* <Select defaultValue="all" className='h-full' onChange={(value) => setFilter(value)}
                        value={filter}
                    >
                        <Select.Option value="all">Todos</Select.Option>
                        {
                            Array.from(statesSet).map((state, index) => (
                                <Select.Option key={index} value={state}>{state}</Select.Option>
                            ))
                        }
                    </Select> */}



                </div>

                <div id='afiliados' className='flex flex-col gap-5 overflow-y-scroll items-start w-[95%] h-[80%] '>

                    {
                        afiliados?.map(afiliado => (
                            <NavLink
                                state={{
                                    home: 1,
                                    movistar: 1,
                                    estado: afiliado.estado,
                                    ciudad: afiliado.ciudad,
                                }}
                                to={afiliado.diff ? `/${cleanString(afiliado.estado)}/${cleanString(afiliado.ciudad)}/${cleanString(afiliado.url)}/${cleanString(afiliado.diff)}` : `/${cleanString(afiliado.estado)}/${cleanString(afiliado.ciudad)}/${cleanString(afiliado.url)}`}

                                class="w-[95%] p-3
                            hover:!bg-[#F99C00] hover:text-white
                            rounded-xl
                            cursor-pointer
                            transition-all
                            duration-300
                            "
                            >
                                <div class="flex flex-col gap-2 ">
                                    <h3 class="text-base capitalize">{afiliado?.nombre_comercial} - {afiliado?.estado} - {afiliado?.ciudad} </h3>

                                </div>
                            </NavLink>
                        ))
                    }

                    {/* {
                        search?.result.length > 0 ? search?.result.sort(
                            (a, b) => {
                                const isReversed = (sortType === 'asc') ? 1 : -1;
                                return isReversed * a.data.name.localeCompare(b.data.name)
                            }
                        ).map(afiliado => (
                            <a
                                href={`/form/${afiliado.data.url}`}

                                class="w-[95%] p-3
                                hover:!bg-[#F99C00] hover:text-white
                                rounded-xl
                                cursor-pointer
                                transition-all
                                duration-300
                                "
                            >
                                <div class="flex flex-col gap-2 ">
                                    <h3 class="text-base capitalize">{afiliado.data.name} - {afiliado.data.state} - {afiliado.data.city}</h3>

                                </div>
                            </a>
                        ))
                            :
                            data?.sort(
                                (a, b) => {
                                    const isReversed = (sortType == 'asc') ? 1 : -1;
                                    return isReversed * a.data.name.localeCompare(b.data.name)
                                }
                            ).map((afiliado, index) => (
                                <a
                                    href={`/form/${afiliado.data.url}`}
                                    key={index}
                                    class="w-[95%] p-3
                            hover:!bg-[#F99C00] hover:text-white
                            rounded-xl
                            cursor-pointer
                            transition-all
                            duration-300
                            "
                                >
                                    <div class="flex flex-col gap-2 ">
                                        <h3 class="text-base capitalize">{afiliado.data.name} - {afiliado.data.state} - {afiliado.data.city}</h3>

                                    </div>
                                </a>
                            ))
                    } */}

                </div>

            </div>
        </div>
    )
}

export default Afiliados