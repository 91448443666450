import React from 'react'
import Deco from '../../../Assets/Deco.svg'
import DecoSub1 from '../../../Assets/DecoSub1.svg'
import HeroMovistar from './hero'
import Afiliados from './atrs'
import { NavLink } from 'react-router-dom'

function MovistarScreen() {
    return (
        <>
            <HeroMovistar />
            <Afiliados />
        </>
    )
}

export default MovistarScreen