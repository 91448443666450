import React, { useEffect, useRef, useState } from 'react'
import Alert from './Alert';
import axios from 'axios';
import ReCAPTCHA from "react-google-recaptcha";
import { FaCheckCircle } from 'react-icons/fa'
import { useForm } from 'react-hook-form';
import { NavLink, useLocation } from 'react-router-dom';
import { Checkbox } from 'antd';
import { alertError } from './alertypes';

function Formulario({ afiliado, afialiadoColors, homeVisit, homeCiudad, homeEstado, setFormCreated }) {

    const location = useLocation()
    const { register, handleSubmit, watch, formState: { errors }, setValue } = useForm()
    const refCaptcha = React.createRef();
    const [formEnviado, setFormEnviado] = useState(false)
    const [loading, setLoading] = useState(false)
    const [statusCaptcha, setStatusCaptcha] = useState(null)
    const [error, setError] = useState(false)
    const [type, setType] = useState('error')
    const [display, setDisplay] = useState(false)
    const [token, setToken] = useState('')
    const [tipoForm, setTipoForm] = useState('')
    const [estados, setEstados] = useState(null)
    const [ciudades, setCiudades] = useState(null)
    const [advertencia, setAdvertencia] = useState(null)
    const [checked, setChecked] = useState(false)





    const getEstados = async () => {
        try {
            const response = await axios.get('https://vb10e3v01k.execute-api.sa-east-1.amazonaws.com/production/regions')
            const filterContainArray = response.data.regionsResponse.filter((estado) => estado.ciudades.length > 0)
            setEstados(filterContainArray)
            if (homeEstado) {
                setValue('estado', homeEstado)
                setValue('ciudad', homeCiudad)
            }
        } catch (e) {
            console.error(e)
        }
    }


    const createForm = async (data) => {
        setLoading(true)
        try {

            if (data.estado !== afiliado.estado && advertencia !== 1) {
                document.getElementById('my_modal_1').showModal();
                setLoading(false)
                return
            }


            if (homeVisit == undefined && data.tipoForm == '') {
                setError('Seleccione un tipo de formulario')
                setType('alert-error')
                setDisplay(true)
                setTimeout(() => {
                    setDisplay(false)
                }, 3000);
                setLoading(false)
                return
            }

            if (!statusCaptcha) {
                setError('Verifique el captcha')
                setType('alert-error')
                setDisplay(true)
                setTimeout(() => {
                    setDisplay(false)
                }, 3000);
                setLoading(false)
                return
            }

            if (checked == false && data.tipoForm == 'Movistar') {
                setError('Confirme que era cliente Movistar')
                setType('alert-error')
                setDisplay(true)
                setTimeout(() => {
                    setDisplay(false)
                }, 3000)
                setLoading(false)
                return
            }

            const inbentaForm = await inventaFormulario(data)


            const insertForm = await axios.post('https://vb10e3v01k.execute-api.sa-east-1.amazonaws.com/production/form', {
                user_name: data.user_name,
                last_name: data.last_name,
                email: data.email,
                phone: data.phone,
                estado: data.estado,
                ciudad: data.ciudad,
                type: homeVisit ? homeVisit : data.tipoForm,
                afiliadoName: afiliado.nombre_comercial,
                inbentaId: inbentaForm.data.uuid,
                decoEstado: data.decoEstado
            })


            const DatalayerFunc = await createDatalayer(data)

            if (insertForm.status == 200) {
                setError('Formulario enviado con éxito')
                setType('alert-success')
                setDisplay(true)
                setTimeout(() => {
                    setDisplay(false)
                }, 3000);
                setLoading(false)
                setFormEnviado(true)
                return
            }


            setLoading(false)
        } catch (e) {
            console.error(e.data)
            setError('Error al enviar el formulario')
            setType('alert-error')
            setDisplay(true)
            setTimeout(() => {
                setDisplay(false)
            }, 3000);
            setLoading(false)
            return
        }

    }



    const inventaFormulario = async (data) => {
        try {
            const jsonSecret = {
                secret: 'eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJwcm9qZWN0Ijoic2ltcGxldHZfdmVfY20iLCJzYWx0IjoiQmdLTjlPUktkWDU0VjdYUEJGUmdpZz09In0.MPN9R0LPgKg8m0BaeRHg356Oz6V0hbTBSXnr7Py1pvDINDNdDQPoptdBeolLR9cVUgS4CuXfucAE_os49MYxqw'
            }
            const responseAuth = await axios.post('https://api.inbenta.io/v1/auth', jsonSecret, {
                headers: {
                    'x-inbenta-key': 'Ba9KoqXrIzys3/Mp0i4J5kmp2eoKxWn2UtYXim0Rwx4='
                }
            })

            if (responseAuth.data.accessToken) {
                setToken(responseAuth.data.accessToken)
                let jsonTicket = {
                    title: location.state.movistar && data.tipoForm == 'Movistar' ? `Canje Movistar - ${afiliado.nombre_comercial}` : `Formulario de contacto ATR - ${afiliado.nombre_comercial}`,
                    message: `nombre:${data.user_name} ,apellido:${data.last_name} ,email:${data.email} ,phone:${data.phone} ,estado:${data.estado} ,ciudad:${data.ciudad} ,tipo:${data.tipoForm}, estado Deco:${data.decoEstado}`,
                    creator: 1143336,
                    priority: 2,
                    queue: location.state.movistar && data.tipoForm == 'Movistar' ? 222 : afiliado.id,
                }

                // DeltaPrint
                if (afiliado.id == 104) {
                    jsonTicket.solver = 886153
                }

                if (afiliado.id == 148) {
                    jsonTicket.solver = 1000640
                }



                const responseCreateTicket = await axios.post('https://api-gcu3.inbenta.io/prod/ticketing/v1/tickets', jsonTicket, {
                    headers: {
                        'x-inbenta-key': 'Ba9KoqXrIzys3/Mp0i4J5kmp2eoKxWn2UtYXim0Rwx4=',
                        'Authorization': `Bearer ${responseAuth.data.accessToken}`
                    }
                })
                return responseCreateTicket

            }

        } catch (e) {
            console.log(e)
        }
    }

    const searchEstadoSelect = () => {
        const ciudades = estados.filter((estado) => estado.name == watch('estado'))
        setCiudades(ciudades[0].ciudades)
        if (homeEstado) {
            setValue('estado', homeEstado)
            setValue('ciudad', homeCiudad)
        }

    }

    const createDatalayer = async (data) => {
        try {
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                event: 'new_form',
                email: data.email,
                name: data.user_name,
                last_name: data.last_name,
                phone: data.phone,
                typeFormulario: homeVisit ? homeVisit : tipoForm,
                afiliado: afiliado.nombre_comercial,
                ciudad: data.ciudad,
                estado: data.estadom,
                decoEstado: data.decoEstado
            })

        } catch (e) {
            console.log(e)
        }
    }

    useEffect(() => {
        setFormCreated(true)
    }, [])


    useEffect(() => {
        if (watch('estado').length > 0) {
            searchEstadoSelect()
        }
    }, [watch('estado')])

    useEffect(() => {
        getEstados()
    }, [])


    console.log(location.state)

    return (
        <form onSubmit={handleSubmit(createForm)} className='w-full max-w-[1000px] flex flex-col gap-5 relative justify-center items-center'>
            <dialog id="my_modal_1" className="modal">
                <div className="modal-box">
                    <h3 className="font-bold text-lg text-red-500">Cuidado!</h3>
                    <p className="py-4">{`El estado elegido es distinto a donde esta ubicado nuestro afiliado ${afiliado.nombre_comercial}, ${afiliado.estado},  ${afiliado.ciudad}, REGION ${afiliado.region}`}</p>
                    <p className="font-bold">¿Deseas continuar?</p>
                    <div className="modal-action justify-center items-center">
                        <form method="dialog">
                            <button className="btn btn-success rounded-2xl px-10" onClick={() => setAdvertencia(1)}>Continuar</button>
                        </form>
                        <NavLink className='btn btn-outline rounded-2xl px-10' to='/'>Buscar Afiliado</NavLink>
                    </div>
                </div>
            </dialog>
            {
                formEnviado == false ?
                    <>
                        <Alert errorText={error} type={type} display={display} />
                        <select {...register('decoEstado', {
                            required: {
                                value: true,
                            }
                        })} className="input input-bordered border-black rounded-3xl w-full">
                            <option value="" selected={
                                location.state.movistar ? false : true
                            } disabled>Selecciona El estado de tu decodificador</option>
                            <option
                                selected={location.state.movistar ? true : false}
                                value="nuevoDeco">Quiero un nuevo Deco</option>
                            <option value="errorDeco">Reactivar mi Deco</option>
                        </select>
                        {
                            homeVisit == undefined ?
                                <select {...register('tipoForm')} className="input input-bordered border-black rounded-3xl w-full">
                                    <option value="" selected={
                                        location.state.movistar ? false : true
                                    } disabled>Selecciona el tipo de formulario</option>
                                    <option value="Error Deco">Ayuda</option>
                                    <option value="Activacion">Activar mi deco</option>
                                    {
                                        location.state.movistar &&
                                        <option value='Movistar'
                                            selected={location.state.movistar ? true : false}
                                        >Promo Movistar</option>

                                    }
                                </select>
                                :
                                null
                        }
                        <select {...register('estado', {
                            required: {
                                value: true,
                                message: 'Estado requerido'
                            }
                        })} className="input input-bordered border-black rounded-3xl w-full">
                            <option value="" selected disabled>Selecciona tu estado</option>
                            {
                                estados?.map((estado) => {

                                    return (
                                        <option value={estado.name}>{estado.name}</option>
                                    )
                                })
                            }
                        </select>


                        <select disabled={watch('estado') ? false : true} {...register('ciudad', {
                            required: {
                                value: true,
                                message: 'Ciudad requerida'
                            }
                        })} className="input input-bordered border-black rounded-3xl w-full">
                            <option value="" className='text-gray-500' selected disabled>{watch('estado') ? 'Selecciona una opcion' : 'Selecciona estado primeramente'}</option>
                            {
                                ciudades?.map((ciudad) => {
                                    return (
                                        <option value={ciudad.name}>{ciudad.name}</option>
                                    )
                                })
                            }
                        </select>
                        <input {...register('email', {
                            required: {
                                value: true,
                                message: 'Email requerido'
                            },
                            pattern: {
                                value: /\S+@\S+\.\S+/,
                                message: 'Email inválido'
                            }
                        })} type="email" placeholder="Correo electrónico *" name='email' className="input input-bordered border-black rounded-3xl w-full" />
                        <div className='w-full flex flex-col gap-5'>
                            <div className='w-full flex flex-col md:flex-row gap-5 '>
                                <input {...register('user_name', {
                                    required: {
                                        value: true,
                                        message: 'Nombre requerido'
                                    }
                                })} type="text" name='user_name' placeholder="Nombre *" className="input input-bordered  md:w-[50%] border-black rounded-3xl" />
                                <input {...register('last_name', {
                                    required: {
                                        value: true,
                                        message: 'Apellido requerido'
                                    }
                                })} type="text" name='last_name' placeholder="Apellido *" className="input input-bordered  md:w-[50%] border-black rounded-3xl" />
                            </div>
                            <div className='w-full flex flex-col gap-5'>
                                <input {...register('phone', {
                                    required: {
                                        value: true,
                                        message: 'Telefono requerido'
                                    },

                                })} type="text" name='phone' placeholder="Teléfono" className="input input-bordered border-black rounded-3xl" />
                            </div>
                        </div>
                        <ReCAPTCHA
                            ref={refCaptcha}
                            sitekey="6LcsAGopAAAAAPTEcu3WlarrXzl0D-YaO5BH1OYx"
                            onChange={(e) => setStatusCaptcha(e)}
                        />

                        {
                            location.state.movistar && watch('tipoForm') == 'Movistar' &&

                            <Checkbox
                                checked={checked}
                                onChange={(e) => setChecked(e.target.checked)}

                            >
                                Confirmo que era cliente Movistar
                            </Checkbox>
                        }


                        {
                            loading ?
                                <span style={{ backgroundColor: afialiadoColors?.textColorPrimary ? afialiadoColors?.textColorPrimary : '#0072CE' }} class="loading loading-spinner loading-lg"></span>
                                :
                                <button disabled={loading} onClick={handleSubmit} style={{ backgroundColor: '#140D57' }} className='btn text-white rounded-xl disabled'>
                                    Enviar mi solicitud
                                </button>

                        }
                    </>
                    :
                    <div className='w-full flex flex-col gap-5 items-center justify-center'>
                        <FaCheckCircle size={120} className='animate-bounce' color={afialiadoColors?.textColorSecondary ? afialiadoColors?.textColorSecondary : '#141414'} />
                        <h3 style={{ color: afialiadoColors?.textColorSecondary ? afialiadoColors?.textColorSecondary : '#141414' }} className='text-3xl font-bold'>¡Tu consulta fue enviada correctamente!</h3>
                        <p className='text-xl'>Nos pondremos en contacto contigo lo mas pronto posible.</p>
                    </div>
            }

        </form>
    )
}

export default Formulario